import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, message} from 'antd';
import {RightOutlined, UserOutlined} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import MyProfileInformation from '@/components/MyProfileInformation'
import MyAddress from '@/components/MyAddress'
import MyList from '@/components/MyList'
import MyOrder from '@/components/MyOrder'
import MyQrcode from '@/components/MyQrcode'
// import MySubordinate from '@/components/MySubordinate'
import OUpload from '@/components/OUpload'

import {getUsingClassName} from '@/common';
import {
  MY_PROFILE_INFORMATION_KEY,
  MY_ADDRESS_KEY,
  MY_LIST_KEY,
  MY_ORDER_KEY,
  MY_PROFILE_PATH_KEY,
  MY_QRCODE_KEY,
  MY_SUBORDINATE_KEY,
  EDIT_ORDER_KEY
} from '@/constants'
import {updateCustomer} from '@/api/req-api';
import {fetchUserInfo} from '@/store/modules/common.slice';

const profileMenuList = [
  {
    label: 'My Profile Information',
    path: MY_PROFILE_INFORMATION_KEY,
  },
  {
    label: 'My Address',
    path: MY_ADDRESS_KEY,
  },
  {
    label: 'My Favorites',
    path: MY_LIST_KEY,
  },
  {
    label: 'My Order',
    path: MY_ORDER_KEY,
  },
  // {
  //   label: 'My QR',
  //   path: MY_QRCODE_KEY,
  // },
  {
    label: 'My Subordinate',
    path: MY_SUBORDINATE_KEY,
  },
]
const pathKeys = profileMenuList.map(item => item.path)
export default function MyProfile() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-profile-mobile-container' : 'my-profile-container'
  const userInfo = commonState.userInfo || {}
  const {IsManager} = userInfo
  
  const layoutRef = useRef(null)
  const oUploadRef = useRef(null)
  
  const [searchParams] = useSearchParams()
  const currentMenuPath = searchParams.get(MY_PROFILE_PATH_KEY)
  
  const [newAvatar, setNewAvatar] = useState(null)
  useEffect(() => {
    setNewAvatar(userInfo.photo || null)
  }, [userInfo.photo])
  const handleAvatarSelectSuccess = filePath => {
    setNewAvatar(filePath)
  }
  const handleAvatarUploadSuccess = resPath => {
    const params = {
      full_name: userInfo.full_name,
      Phone_Number: userInfo.Phone_Number,
      Company: userInfo.business_name,
      Company_Phone: userInfo.business_phone,
      Business_Type: userInfo.BusinessType,
      Number: userInfo.EIN,
      photo: resPath
    }
    updateCustomer(params).then(() => {
      message.success(t('Saved successfully'))
      dispatch(fetchUserInfo())
    })
  }
  const handleAvatarSaveClick = () => {
    oUploadRef.current?.startUpload()
  }
  const handleAvatarCancelClick = () => {
    setNewAvatar(userInfo.photo || null)
  }
  
  const useProfileMenuList = useMemo(() => {
    // if (!IsManager) {
      return profileMenuList.filter(item => item.path !== MY_SUBORDINATE_KEY)
    // }
    // return profileMenuList
  }, [IsManager])
  const handleMenuClick = item => {
    searchParams.set(MY_PROFILE_PATH_KEY, item?.path || MY_PROFILE_INFORMATION_KEY)
    const fullPath = `${location.pathname}?${searchParams.toString()}`
    navigate(fullPath, {replace: true})
  }
  const layoutRestoreScrollState = () => {
    layoutRef.current?.restoreScrollState()
  }
  useEffect(() => {
    if (!pathKeys.includes(currentMenuPath)) {
      handleMenuClick()
    }
    layoutRestoreScrollState()
  }, [searchParams])
  
  const renderMenuItem = (item, index) => {
    const isAdd = item.path === currentMenuPath
    const className = getUsingClassName(
      ['my-profile-left-menu-item-container'],
      ['my-profile-left-menu-item-active-container'],
      isAdd
    )
    return <div
      className={className}
      onClick={() => handleMenuClick(item)}
      key={`profile_menu_list_${index}`}>
      <div>{t(item.label)}</div>
      <RightOutlined />
    </div>
  }
  
  return (
    <Layout
      ref={layoutRef}
      headerLogoutIsGoHome={true}
      isDetailPage={true}
      detailPageTitle={t('My Profile')}>
      <div className={rootClassName}>
        <div className={'my-profile-left-container'}>
          <div className={'my-profile-left-user-info-container'}>
            <OUpload
              ref={oUploadRef}
              className={'my-profile-left-user-info-upload-container'}
              onUploadSuccess={handleAvatarUploadSuccess}
              onSelectSuccess={handleAvatarSelectSuccess}>
              <div className={'my-profile-left-user-info-avatar-container'}>
                {
                  newAvatar &&
                  <img src={newAvatar} alt="" />
                }
                {
                  !newAvatar &&
                  <UserOutlined />
                }
              </div>
            </OUpload>
            {
              newAvatar &&
              newAvatar !== userInfo.photo &&
              <div>
                <Button
                  type={'link'}
                  onClick={handleAvatarSaveClick}>
                  {t('Save')}
                </Button>
                <Button
                  type={'link'}
                  onClick={handleAvatarCancelClick}>
                  {t('Cancel')}
                </Button>
              </div>
            }
            <div className={'my-profile-left-user-info-username-container'}>
              {t('Name')}: {userInfo.FirstName} {userInfo.LastName}
            </div>
          </div>
          <div className={'my-profile-left-menu-container'}>
            {useProfileMenuList.map((item, index) => renderMenuItem(item, index))}
          </div>
        </div>
        <div className={'my-profile-right-container'}>
          {
            currentMenuPath === MY_PROFILE_INFORMATION_KEY &&
            <MyProfileInformation className={'my-profile-right-profile-information-container'} />
          }
          {
            currentMenuPath === MY_ADDRESS_KEY &&
            <MyAddress layoutRestoreScrollState={layoutRestoreScrollState} />
          }
          {
            currentMenuPath === MY_LIST_KEY &&
            <MyList />
          }
          {
            currentMenuPath === MY_ORDER_KEY &&
            <MyOrder
              className={'my-profile-right-order-container'}
              layoutRestoreScrollState={layoutRestoreScrollState} 
            />
          }
          {
            currentMenuPath === EDIT_ORDER_KEY &&
            <MyOrder
              className={'my-profile-right-order-container'}
              layoutRestoreScrollState={layoutRestoreScrollState} 
            />
          }
          {
            currentMenuPath === MY_QRCODE_KEY &&
            <MyQrcode />
          }
          {/* {
            currentMenuPath === MY_SUBORDINATE_KEY &&
            <MySubordinate layoutRestoreScrollState={layoutRestoreScrollState} />
          } */}
        </div>
      </div>
    </Layout>
  )
}
