import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, DatePicker, Empty, message, Select, TreeSelect} from 'antd'
import {SearchOutlined} from '@ant-design/icons'

import './index.less'
import './mobile.less'

import {getFormatDate, getUsingClassName, setEditOrderIsGetOrderDetails, isOver8FromEast} from '@/common';
import {cancelOrder, getOrderList} from '@/api/req-api';

import {DATE_FORMAT_2, DATE_FORMAT_3} from '@/constants';

import MyOrderItem from '@/components/MyOrderItem'
import MyOrderDetail from '@/components/MyOrderDetail'
import {fetchSubCustomer} from '@/store/modules/subordinate.slice';

const allOrderVal = 'All Orders'
const msoOrderVal = 'MSO'

const orderTypeOptions = [
  {
    value: msoOrderVal,
    label: 'Online (MSO)',
  },
  {
    value: 'SO',
    label: 'Offline (SO)',
  },
]

const orderStatusOptions = [
  {
    value: 'Open',
    label: 'Open',
  },
  {
    value: 'Packing',
    label: 'Packing',
  },
  {
    value: 'Released',
    label: 'Released',
  },
  {
    value: 'Finalized',
    label: 'Finalized',
  },
  {
    value: 'Canceled',
    label: 'Void'
  },
  {
    value: allOrderVal,
    label: 'All Orders',
  },
]

const defaultProps = {
  className: '',
  layoutRestoreScrollState: () => {},
};

export default function MyOrder(props) {
  const options = Object.assign({}, defaultProps, props);

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const subordinateState = useSelector(state => state.subordinate)
  const rootClassName = commonState.isMobile ? 'my-order-mobile-container' : 'my-order-container'

  const {userInfo, locale} = commonState
  const customerId = userInfo?.Customer_id
  const IsManager = userInfo?.IsManager

  const {subCustomer} = subordinateState
  
  const [isDetailPage, setIsDetailPage] = useState(false)
  const [curOrderId, setCurOrderId] = useState('')

  const [rangePickerVal, setRangePickerVal] = useState([])
  const [orderTypeVal, setOrderTypeVal] = useState(msoOrderVal)
  const [orderStatusVal, setOrderStatusVal] = useState(allOrderVal)
  const [orderList, setOrderList] = useState([])
    
  const [subCustomerVal, setSubCustomerVal] = useState([])
  const [subMobileId, setSubMobileId] = useState(undefined)
  
  const useOrderTypeOptions = useMemo(() => {
    return orderTypeOptions.map(item => {
      return {
        ...item,
        label: t(item.label)
      }
    })
  }, [orderTypeOptions, locale])
  
  
  const useOrderStatusOptions = useMemo(() => {
    return orderStatusOptions.map(item => {
      return {
        ...item,
        label: t(item.label)
      }
    })
  }, [orderStatusOptions, locale])

  const ALL_SUBORDINATE_VAL = '-1'
  const AllSubordinateChildren = useMemo(() => {
    return subCustomer.map(item => {
      return {
        title: item.name,
        value: item.MobileUser_id,
      }
    })
  }, [subCustomer])
  const subCustomerOptions = useMemo(() => {
    return [
      {
        title: t('All Subordinate'),
        value: ALL_SUBORDINATE_VAL,
        children: AllSubordinateChildren
      }
    ]
  }, [AllSubordinateChildren, locale])
  useEffect(() => {
    IsManager && dispatch(fetchSubCustomer())
  }, [IsManager])

  const getOrderListByApi = (type = msoOrderVal, status = allOrderVal ) => {
    const startTime = getFormatDate(rangePickerVal?.[0], DATE_FORMAT_3)
    const endTime = getFormatDate(rangePickerVal?.[1], DATE_FORMAT_3)
    
    getOrderList(
        getFormatDate(startTime, DATE_FORMAT_2), 
        getFormatDate(endTime, DATE_FORMAT_2), 
        subMobileId,
        type,
        status === allOrderVal ? undefined : status
    ).then(res => {
      setOrderList(res.data)
    })
  }
  useEffect(() => {
    getOrderListByApi()
  }, [rangePickerVal, subMobileId])

  const handleRangePickerChange = (dates, dateStrings) => {
    setRangePickerVal(dates)
  }
  
  const handleOrderTypeChange = newOrderTypeVal => {
    setOrderTypeVal(newOrderTypeVal)
    getOrderListByApi(newOrderTypeVal, orderStatusVal)
  }
  const handleOrderStatusChange = newOrderStatusVal => {
    setOrderStatusVal(newOrderStatusVal)
    getOrderListByApi(orderTypeVal, newOrderStatusVal)
  }
  const handleSubCustomerChange = newSubCustomerVal => {
    setSubCustomerVal(newSubCustomerVal)
  }
  const handleSubCustomerSearchClick = () => {
    let subIdStr = subCustomerVal.join(',')
    if (subIdStr === ALL_SUBORDINATE_VAL) {
      subIdStr = AllSubordinateChildren.map(item => item.value).join(',')
    }
    setSubMobileId(subIdStr || undefined)
  }

  const handleDetailBackClick = () => {
    setIsDetailPage(false)
  }
  const handleViewOrderDetailsClick = item => {
    setCurOrderId(item.SalesOrder_id)
    setIsDetailPage(true)
  }
  const handleEditClick = item => {
    if(item.SaleStatus != 'Open' || isOver8FromEast(item.OrderDate)) {
      message.warning(t('Order is packing，not allowed to modify.'))
      return
    }
    setEditOrderIsGetOrderDetails(true)
    navigate(`/editOrder/${item.SalesOrder_id}`)
  }
  const handleVoidClick = item => {
    cancelOrder(item.SalesOrder_id).then(() => {
      message.success(t('Void successfully'))
      getOrderListByApi()
    }).catch(() => {})
  }
  useEffect(() => {
    options.layoutRestoreScrollState()
  }, [isDetailPage])
  
  const getIsHideEditVoidBtn = item => {
    const {SaleStatus, IsBypass, PaymentStatus} = item
    const openStatusKey = 'Open'
    
    if (IsBypass && SaleStatus !== openStatusKey) {
      return true
    }
    if (['Paid', 'Partially Paid'].includes(PaymentStatus)) {
      return true
    }
    if (['Not Paid'].includes(PaymentStatus) && SaleStatus !== openStatusKey) {
      return true
    }
    if (SaleStatus==='Canceled') {
      return true
    }
  }
  
  const getIsHideReorderVoidBtn = item => {
    const {SaleStatus, IsBypass, PaymentStatus} = item
    if (SaleStatus==='Canceled') {
      return true
    }
  }
  
  const renderOrderItem = (item, index) => {
    const isShowEditBtn = !getIsHideEditVoidBtn(item)
    const isShowVoidBtn = !getIsHideEditVoidBtn(item)
    const isShowReorderBtn = !getIsHideReorderVoidBtn(item)
    
    return <MyOrderItem
      item={item}
      onViewOrderDetailsClick={() => handleViewOrderDetailsClick(item)}
      isShowEditBtn={isShowEditBtn}
      isShowVoidBtn={isShowVoidBtn}
      isShowReorderBtn={isShowReorderBtn}
      onEditClick={() => handleEditClick(item)}
      onVoidClick={() => handleVoidClick(item)}
      key={`order_list_${index}`}
    />
  }
  
  const renderDefaultPage = () => {
    return <>
      <div className={'my-order-top-container'}>
        <div className={'my-order-top-item-container'}>
          <div className={'my-order-top-item-title'}>{t('Date')}</div>
          <DatePicker.RangePicker
            className={'my-order-top-range-picker-container'}
            value={rangePickerVal}
            format={DATE_FORMAT_3}
            onChange={handleRangePickerChange}
            showTime
          />
        </div>
        <div className={'my-order-top-item-container2'}>
          <div className={'my-order-top-item-container'}>
            <div className={'my-order-top-item-title'}>{t('Order Type')}</div>
            <Select
              className={'my-order-top-item-select-container'}
              popupClassName={'my-order-top-item-select-popup-container'}
              value={orderTypeVal}
              onChange={handleOrderTypeChange}
              options={useOrderTypeOptions}
            />
          </div>
          <div className={'my-order-top-item-container'}>
            <div className={'my-order-top-item-title'}>{t('Order Status')}</div>
            <Select
              className={'my-order-top-item-select-container'}
              popupClassName={'my-order-top-item-select-popup-container'}
              value={orderStatusVal}
              onChange={handleOrderStatusChange}
              options={useOrderStatusOptions}
            />
          </div>
        </div>
      </div>
      <div className={'my-order-list-container'}>
        {
          !orderList.length &&
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
        {orderList.map((item, index) => renderOrderItem(item, index))}
      </div>
      {/*<div className={'my-order-show-more-btn-container'}>*/}
      {/*  Click to show more*/}
      {/*</div>*/}
    </>
  }
  
  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )

  return (
    <div className={className}>
      {
        !isDetailPage && renderDefaultPage()
      }
      {
        isDetailPage &&
        <MyOrderDetail
          orderId={curOrderId}
          onBack={handleDetailBackClick}
        />
      }
    </div>
  )
}
