import React, {useEffect, useMemo, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {message, Button} from 'antd';

import './index.less'
import './mobile.less'

import UserInfoForm from '@/components/UserInfoForm'

import {getUsingClassName} from '@/common';
import {updateCustomer} from '@/api/req-api';
import {fetchUserInfo} from '@/store/modules/common.slice';
import { useNavigate } from 'react-router-dom';
import eventBus from '@/common/EventBus';

import {
  LOGOUT_EVENT_NAME,
} from '@/constants'

const defaultProps = {
  className: '',
};

export default function MyProfileInformation(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'my-profile-information-mobile-container' : 'my-profile-information-container'
  
  const userInfo = useMemo(() => {
    return commonState.userInfo || {}
  }, [commonState.userInfo])
  
  const IsManager = useMemo(() => {
    return userInfo.IsManager
  }, [userInfo])
  
  
  const initialValues = useMemo(() => {
    const {
      FirstName, // full_name,
      LastName,
      email,
      phone, // Phone_Number,
      business_name, // <-
      business_phone, // <-
      BusinessType, // <-
      EIN, // <-
    } = userInfo
    return {
      name: FirstName + LastName,
      emailAddress: email,
      phoneNumber: phone,
      company: business_name,
      companyPhone: business_phone,
      businessType: BusinessType,
      einNumber: EIN,
    }
  }, [userInfo])
  
  const disabledInputNameList = useMemo(() => {
    const defaultList = ['emailAddress']
    const notManagerList = ['emailAddress', 'company', 'companyPhone','businessType', 'einNumber']
    return IsManager ? defaultList : notManagerList
  }, [IsManager])
 
  const userInfoFormRef = useRef(null);

  const resetFields = () => userInfoFormRef.current?.resetFields()
  const onFormFinish = (values) => {
    const params = {
      full_name: values.name,
      Phone_Number: values.phoneNumber,
      Company: values.company,
      Company_Phone: values.companyPhone,
      Business_Type: values.businessType,
      Number: values.einNumber,
      photo: userInfo.photo
    }
    var isChangePassword = false;
    if(values.password && values.new_password) {
      if(values.password == values.new_password) {
        message.error(t('The new password is the same as the old password'))
        return
      }
      params.password1 = values.password;
      params.password2 = values.new_password;
      isChangePassword = true;
    }
    updateCustomer(params).then(() => {
      message.success('Saved successfully')
      if(isChangePassword) {
        eventBus.dispatchEvent(LOGOUT_EVENT_NAME)
        navigate('/home')
      } else {
        dispatch(fetchUserInfo())
      }
    })
  }
  const handleSaveClick = () => {
    userInfoFormRef.current?.submit()
  }

  useEffect(() => {
    resetFields()
  }, [initialValues])

  const className = getUsingClassName(
    [rootClassName],
    [options.className]
  )
  const btnClassName = getUsingClassName(
    ['my-profile-information-btn'],
    ['my-profile-information-disabled-btn'],
    false
  )
  return (
    <div className={className}>
      <UserInfoForm
        className={'my-profile-information-form-container'}
        ref={userInfoFormRef}
        isShowBaseInfo={true}
        isShowCompanyInfo={true}
        isShowAddressInfo={false}
        isShowPassword={true}
        isShowEmail={IsManager}
        isChangePassword={true}
        onFormFinish={onFormFinish}
        initialValues={initialValues}
        disabledInputNameList={disabledInputNameList}
      />
      <Button
        className={btnClassName}
        onClick={handleSaveClick}>
        {t('Save')}
      </Button>
    </div>
  )
}
