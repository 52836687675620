import instance from '../http-client'
import {
  CONTENT_TYPE,
  FORM_DATA,
  HTTP_DELETE,
  HTTP_GET,
  HTTP_POST,
  HTTP_PUT,
  PRODUCT_DEFAULT_LIMIT
} from '@/constants'
import {
  getLocationInfo
} from '@/common';

export const API_URL = `/api`;
export const API_V1_URL = `${API_URL}/v1`;
export const MOBILE_URL = `${API_V1_URL}/mobile`;
export const getCategory2 = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/category2/`,
    params: {
      ...locationInfo,
      IsListView: true,
      nested: true,
    }
  })
}
export const getCategory2B2B = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/category2_b2b/`,
    params: {
      ...locationInfo,
      IsListView: true,
      nested: true,
      isB2B: true
    }
  })
}
export const getBillboard = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/billboard/`,
    params: {
      ...locationInfo,
      Mobile: true,
    }
  })
}

export const addToCart = function (item, quantity = 1) {
  const locationInfo = getLocationInfo()
  // this is oolong 
  // let data = [];
  // if(Array.isArray(item)) {
  //   data = [...item]
  // } else {
  //   data.push({ Product_id: item.Product_id,Quantity: quantity})
  // }
  let data = { Product_id: item.Product_id,Quantity: quantity}
  
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/order-cart`,
    data,
    params: {
      ...locationInfo,
    }
  })
}
export const updateItemQuantity = function (item, quantity) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${MOBILE_URL}/order-cart`,
    data: {
      Product_id: item.Product_id,
      Quantity: quantity,
    },
    params: {
      ...locationInfo,
    }
  })
}
export const deleteCartItem = function (item) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_DELETE,
    url: `${MOBILE_URL}/order-cart`,
    data: {
      Product_id: item.Product_id,
    },
    params: {
      ...locationInfo,
    }
  })
}
export const getOrderCart = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/order-cart`,
    params: {
      ...locationInfo,
    }
  })
}
export const getCart = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/cart/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const getCartInfo = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${MOBILE_URL}/update_cart/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const updateOrder = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${MOBILE_URL}/update_order/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const getOrderList = function (startDate, endDate, subMobileId, type, status) {
  let timeFrame = ''
  if (!startDate || !endDate) {
    timeFrame = 99
  }
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/order/${timeFrame}`,
    params: {
      ...locationInfo,
      start_date: startDate,
      end_date: endDate,
      sub_mobile_id: subMobileId,
      SaleStatus: status,
      is_mobile: type==='MSO' ? true : false,
    }
  })
}
export const getSalesOrderDetails = function (salesOrderId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/sales_order/`,
    params: {
      ...locationInfo,
      pk: salesOrderId
    }
  })
}
export const getSalesInvoiceDetails = function (salesOrderId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/sales_invoice/`,
    params: {
      ...locationInfo,
      pk: salesOrderId
    }
  })
}

export const getSalesInvoiceReturnDetails = function (salesOrderId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/sales_return/`,
    params: {
      ...locationInfo,
      pk: salesOrderId
    }
  })
}

export const submitOrder = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/order/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const applyDiscountCode = function (code, totalPrice) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/promocode/`,
    params: {
      PromoCode: code,
      TotalPrice: totalPrice,
      ...locationInfo,
    }
  })
}
export const cancelOrder = function (orderId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${MOBILE_URL}/order/cancel/${orderId}`,
    params: {
      ...locationInfo,
    }
  })
}
export const baseUserCheckTaken = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/baseuser/checktaken/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const registerBusinessCustomer = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/baseuser/business-info`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const getApiTokenAuth = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_URL}/api-token-auth/`,
    params: {
      ...locationInfo,
    },
    data: data
  })
}
export const getCustomerOao = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/customer/oao/`,
    params: {
      ...locationInfo,
    }
  })
}
export const updateCustomer = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${API_V1_URL}/customer/update_customer/`,
    params: {
      ...locationInfo,
    },
    data
  })
}

export const uploadCustomerPhoto = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/customer/add_photo/`,
    params: {
      ...locationInfo,
    },
    data,
    headers: {
      [CONTENT_TYPE]: FORM_DATA
    }
  })
}

export const getProductDetail = function (num) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/product/${num}`,
    params: {
      ...locationInfo,
    }
  })
}

export const getMoreProducts = function (categoryId, limit = 8) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/group/${categoryId}`,
    params: {
      ...locationInfo,
      IsMore: true,
      page:1,
      limit
    },
  })
}

export const getProductGroup = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/group/`,
    params: {
      ...locationInfo,
    }
  })
}
export const getGroupProducts = function (groupId, sort, stock, page = 1, limit = PRODUCT_DEFAULT_LIMIT) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/group/${groupId}`,
    params: {
      ...locationInfo,
      sort,
      page,
      limit,
      stock_level: stock,
    }
  })
}
export const getCategoryProducts = function (categoryId, sort, stock, page = 1, limit = PRODUCT_DEFAULT_LIMIT) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/category/${categoryId}`,
    params: {
      ...locationInfo,
      sort,
      page,
      limit,
      stock_level: stock,
    }
  })
}
export const getAllProducts = function (name, sort, stock, page = 1, limit = PRODUCT_DEFAULT_LIMIT) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/product/`,
    params: {
      ...locationInfo,
      Name: name,
      sort,
      page,
      limit,
      stock_level: stock,
    }
  })
}
//https://afg-scm.truckxi.com/api/v1/mobile/category2_b2b/44?isB2B=true
export const getCat2Products = function (category2Id) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/category2_b2b/${category2Id}?isB2B=true`,
  })
}

export const getFavoriteList = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/mobile/favorite/`,
    params: {
      ...locationInfo,
    }
  })
}
export const addProductToFavoriteList = function (item, quantity = 1) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/mobile/favorite/${item.Product_id}`,
    data: {
      ...locationInfo,
      quantity,
    }
  })
}
export const removeProductFromFavoriteList = function (item) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/mobile/unfavorite/${item.Product_id}`,
    params: {
      ...locationInfo,
    }
  })
}

export const getAddressList = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/address`,
    params: {
      ...locationInfo
    }
  })
}

export const getDefaultAddress = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${MOBILE_URL}/address/default`,
    params: {
      ...locationInfo
    }
  })
}

export const addAddress = function (address) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/address`,
    params: {
      ...locationInfo
    },
    data: address
  })
}

export const editAddress = function (address, customerDetailId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_PUT,
    url: `${MOBILE_URL}/address/${customerDetailId}`,
    params: {
      ...locationInfo
    },
    data: address
  })
}

export const deleteAddress = function (customerDetailId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_DELETE,
    url: `${MOBILE_URL}/address/${customerDetailId}`,
    params: {
      ...locationInfo
    }
  })
}

export const reqResetPassword = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/account/req-reset-password/${data}/`,
    params: {
      ...locationInfo
    }
  })
}
export const verificationCodeActive = function (code, token) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/account/verify-active/${code}/`,
    params: {
      ...locationInfo
    },
    data: {token}
  })
}
export const resetPassword = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${MOBILE_URL}/account/reset-password/`,
    params: {
      ...locationInfo
    },
    data: data
  })
}

export const getBalance = function (customerId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/ar/report/aged/${customerId}/`,
    params: {
      ...locationInfo
    }
  })
}
export const getBalanceDetail = function (customerId, startDate, endDate) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/ar/report/aged/${customerId}/print/`,
    params: {
      ...locationInfo,
      start_date: startDate,
      end_date: endDate
    },
    responseType: 'blob'
  })
}

export const generateQrcode = function (customerNum) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/customer/generate_qrcode`,
    params: {
      ...locationInfo
    },
    data:{
      CustomerNum: customerNum
    },
    responseType: 'blob'
  })
}
export const getWarehouse = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/customer/get_warehouse/`,
    params: {
      ...locationInfo
    }
  })
}
export const addSubCustomer = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/customer/main_sub/`,
    params: {
      ...locationInfo
    },
    data
  })
}
export const deactiveSubCustomer = function (subMobileId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_DELETE,
    url: `${API_V1_URL}/customer/main_sub/`,
    params: {
      ...locationInfo
    },
    data: {
      sub_mobile_id: subMobileId,
      IsActive: false,
    }
  })
}
export const activeSubCustomer = function (subMobileId) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_DELETE,
    url: `${API_V1_URL}/customer/main_sub/`,
    params: {
      ...locationInfo
    },
    data: {
      sub_mobile_id: subMobileId,
      IsActive: true,
    }
  })
}
export const getSubCustomer = function () {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/customer/get_sub/`,
    params: {
      ...locationInfo
    }
  })
}

export const seedContactInfo = function (data) {
  const locationInfo = getLocationInfo()
  return instance({
    method: HTTP_POST,
    url: `${API_V1_URL}/contact/info/`,
    params: {
      ...locationInfo
    },
    data,
  })
}

export const printSalesOrder = function (orderId) {
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/sale/printorder/${orderId}`,
    responseType: 'blob',
  })
}

export const printSalesInvoice = function (invoiceId) {
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/sale/printinvoice/${invoiceId}`,
    responseType: 'blob',
  })
}

export const printSalesInvoiceReturn = function (returnId) {
  return instance({
    method: HTTP_GET,
    url: `${API_V1_URL}/sale/printreturn/${returnId}`,
    responseType: 'blob',
  })
}
